<template>
    <div class="dashboard-wrap">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-12 col-xl-8">
                <h1 class="dashboard-title">
                    <span>Ваши кошельки</span>
                    <router-link to="/dashboard/payment-services/259" class="button md-block">Вывод на карту</router-link>
                </h1>
                <div class="dashboard-personal-wrap p-0">
                    <div class="main-balance-wrap" v-for="item in balances" :key="item">
                        <div class="main-balance-name">
                            <img :src="item.logo" :alt="item.name">
                            <div>
                                <span>{{ item.currency }}</span>
                                <b>{{ item.name }}</b>
                            </div>
                        </div>
                        <div class="main-balance-value">
                            <span>{{ item.balance }}</span>
                            <b :class="item.converted_balance > 0 ? 'text-success' : 'text-danger'">${{ item.converted_balance }}</b>
                        </div>
                        <div class="main-balance-action">
                            <router-link :to="'/dashboard/refill/cryptocurrency?currency=' + item.currency" v-if="item.can_deposit === true">Ввод</router-link>
                            <a class="disabled" v-else>Ввод</a>
                            <router-link :to="'/dashboard/withdraw/detail/' + item.currency" v-if="item.can_withdraw === true">Вывод</router-link>
                            <a class="disabled" v-else>Вывод</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-4">
                <div class="row">
                    <div class="col-lg-6 col-xl-12">
                        <h1 class="dashboard-title">Ваши данные</h1>
                        <div class="dashboard-personal-wrap" v-if="profile">
                            <h1 class="title">Лимиты за текущий день</h1>
                            <div class="profile-info-column icon-none">
                                <div>
                                    <span>Пополнение</span>
                                    <b>{{ profile.limits?.refill }} USDT</b>
                                </div>
                            </div>
                            <div class="profile-info-column icon-none">
                                <div>
                                    <span>Покупка</span>
                                    <b>{{ profile.limits?.buy }} USDT</b>
                                </div>
                            </div>
                            <div class="profile-info-column icon-none">
                                <div>
                                    <span>Продажа</span>
                                    <b>{{ profile.limits?.sell }} USDT</b>
                                </div>
                            </div>
                            <div class="profile-info-column icon-none">
                                <div>
                                    <span>Вывод</span>
                                    <b>{{ profile.limits?.withdraw }} USDT</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-12">
                        <div class="dashboard-personal-wrap" v-if="profile">
                            <h1 class="title">Персональные данные</h1>
                            <div class="profile-info-column calendar">
                                <div>
                                    <span>Дата регистрации</span>
                                    <b>{{ profile.date_register }}</b>
                                </div>
                            </div>
                            <div class="profile-info-column exit">
                                <div>
                                    <span>Последний вход</span>
                                    <b>{{ profile.last_access }}</b>
                                </div>
                            </div>
                            <div class="profile-info-column email">
                                <div>
                                    <span>Ваша почта</span>
                                    <b>{{ profile.email }}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close"></div>
        <div class="modal-content padding-none">
            <div class="p-3">
                <h1 class="modal-title">Опрос</h1>
                <span class="modal-subtitle mb-0">Пожалуйста, ответьте на вопросы</span>
            </div>
            <form class="form position-relative z-index" autocomplete="off">
                <SurveyComponent :model="survey" @onSubmit="handleSurvey" />
                <div class="p-3" v-if="surveyRetryStatus">
                    <button class="button block lg" v-on:click="createSurvey">Попробовать еще раз</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { Model } from 'survey-core'
import "survey-core/i18n/russian"
import { LayeredDarkPanelless } from "survey-core/themes"
import 'survey-core/defaultV2.min.css'

export default {
    data() {
        return {
            modalStatus: false,
            surveyRetryStatus: false,
            survey: ''
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        balances() {
            return (this.$store.getters.getCurrencies)
            ? this.$store.getters.getCurrencies
            : this.$store.dispatch('getCurrencies')
        },
        profile() {
            return (this.$store.getters.getProfile)
            ? this.$store.getters.getProfile
            : this.$store.dispatch('getProfile')
        },
        actions() {
            return (this.$store.getters.getActionList)
            ? this.$store.getters.getActionList
            : this.$store.dispatch('getActionList')
        }
    },
    watch: {
        profile() {
            if(!this.profile.survey_status) {
                if(localStorage.getItem('survey') !== 'passed') {
                    this.modalStatus = true
                    this.createSurvey()
                }
            }
        }
    },
    methods: {
        createSurvey() {
            const surveyJson = {
                goNextPageAutomatic: false,
                firstPageIsStarted: true,
                pages: [
                    {
                        elements: [{
                            "type": "radiogroup",
                            "name": "survey1",
                            "title": "Виртуальный актив может быть высоковолатильным активом при использовании которого могут возникать существенные потери из-за изменений курса",
                            "choices": ["Да", "Нет"],
                            "correctAnswer": "Да",
                            "isRequired": true,
                            "separateSpecialChoices": true
                        }]
                    },
                    {
                        elements: [{
                            "type": "radiogroup",
                            "name": "survey2",
                            "title": "Виртуальный актив не обеспечен и не гарантирован государством Кыргызской Республики",
                            "choices": ["Да", "Нет"],
                            "correctAnswer": "Да",
                            "isRequired": true,
                            "separateSpecialChoices": true
                        }]
                    },
                    {
                        elements: [{
                            "type": "radiogroup",
                            "name": "survey3",
                            "title": "В случае противоправных действий и технических сбоев пользователь может лишиться всех виртуальных активов и иных объектов гражданских прав, переданных в обмен на виртуальные активы",
                            "choices": ["Да", "Нет"],
                            "correctAnswer": "Да",
                            "isRequired": true,
                            "separateSpecialChoices": true
                        }]
                    },
                    {
                        elements: [{
                            "type": "radiogroup",
                            "name": "survey4",
                            "title": "Виртуальный актив не является законным средством платежа в Кыргызской Республике",
                            "choices": ["Да", "Нет"],
                            "correctAnswer": "Да",
                            "isRequired": true,
                            "separateSpecialChoices": true
                        }]
                    },
                    {
                        elements: [{
                            "type": "radiogroup",
                            "name": "survey5",
                            "title": "У некоторых виртуальных активов отсутствует централизованный орган, ответственный за проведение операций с ними, что делает невозможным реализацию процессов отмены и возврата",
                            "choices": ["Да", "Нет"],
                            "correctAnswer": "Да",
                            "isRequired": true,
                            "separateSpecialChoices": true
                        }]
                    }
                ],
                showQuestionNumbers: false,
                completedHtml: "Вы ответили {correctAnswers} из {questionCount} правильных ответов.",
                completedHtmlOnCondition: [
                    {
                        "expression": "{correctAnswers} == 0",
                        "html": "К сожалению, ни один из ваших ответов не является правильным. Пожалуйста, попробуйте еще раз."
                    }
                ]
            }
            this.surveyRetryStatus = false
            this.survey = new Model(surveyJson)
            this.survey.locale = "ru"
            this.survey.applyTheme(LayeredDarkPanelless)
            this.survey.onComplete.add((sender) => {
                this.handleSurvey(sender.data)
            })
        },
        handleSurvey(data) {
            let surveyStatus = true
            Object.entries(data).forEach((item) => {
                if(item[1] !== 'Да') {
                    surveyStatus = false
                    this.surveyRetryStatus = true
                }
            })
            if(surveyStatus === true) {
                this.$store.dispatch('updateSurveyStatus')
                localStorage.setItem('survey', 'passed')
                this.modalStatus = false
            }
        }
    }
}
</script>